import { Stack } from 'react-bootstrap';
import starterImg from 'assets/img/spot-illustrations/37.png';
import starterDarkImg from 'assets/img/spot-illustrations/37_2.png';
import Button from 'components/base/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FacebookPixel from 'FacebookPixel';
import { useEffect, useState } from 'react';

const ResumeThankYou = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get('plan');
  const [purchaseValue, setPurchaseValue] = useState(0);

  if (plan === 'amateur') {
    setPurchaseValue(42);
  } else if (plan === 'professional') {
    setPurchaseValue(240);
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/resume/ai');
    }, 5000);

    return () => clearTimeout(timer);
  }, [history]);

  return (
    <Stack className="flex-center content-min-h">
      <FacebookPixel
        event="Purchase"
        purchaseValue={purchaseValue}
        currency="USD"
      />
      <div className="text-center py-9">
        <img
          src={starterImg}
          width={470}
          className="img-fluid mb-7 d-dark-none"
          alt="starter"
        />
        <img
          src={starterDarkImg}
          width={470}
          className="img-fluid mb-7 d-light-none"
          alt="starter"
        />
        <h1 className="text-body-secondary fw-normal mb-5">
          Obrigado por escolher o Giga!
        </h1>
        <h2 className="text-body-secondary fw-normal mb-7">
          Você será redirecionado em 5 segundos.
        </h2>
        <Button as={Link} className="btn btn-lg btn-primary" to="/resume/ai">
          Ir para criação de currículo Internacional
        </Button>
      </div>
    </Stack>
  );
};

export default ResumeThankYou;
