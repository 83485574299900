import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import React from 'react';

interface AuthSocialButtonsProps {
  title: string;
  onClick: () => void;
}

const AuthSocialButtons: React.FC<AuthSocialButtonsProps> = ({
  title,
  onClick
}) => {
  return (
    <>
      <Button
        //onClick={() => signInWithRedirect({ provider: 'Google' })}
        onClick={onClick}
        variant="phoenix-secondary"
        className="w-100 mb-3"
        startIcon={
          <FontAwesomeIcon icon={faGoogle} className="text-danger me-2 fs-9" />
        }
      >
        {title} with Google
      </Button>
    </>
  );
};

export default AuthSocialButtons;
