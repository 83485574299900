import { Card, Col, Container, Row } from 'react-bootstrap';
import bg37 from 'assets/img/bg/37.png';
import bg38 from 'assets/img/bg/38.png';
import authIllustrations from 'assets/img/spot-illustrations/22.png';
import authIllustrationsDark from 'assets/img/spot-illustrations/22.png';
import { PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { resumeBreadcrumbItems } from 'data/commonData';
import { Link } from 'react-router-dom';
import { useResumeServiceContext } from 'providers/ResumeServiceProvider';

interface AuthCardLayoutProps {
  logo?: boolean;
  className?: string;
}

const AuthCardLayout = ({
  className,
  children
}: PropsWithChildren<AuthCardLayoutProps>) => {
  const [loadingFetchData, setLoadingFetchData] = useState(false);

  const { credits, tasks, token, handleFetchProcessedResume } =
    useResumeServiceContext();

  // const authAndLoad = async () => {
  //   try {
  //     loadData();
  //   } catch (error) {
  //     setCredits(0);
  //     setTasks([]);
  //   }
  // };

  useEffect(() => {
    setLoadingFetchData(true);
    handleFetchProcessedResume().finally(() => {
      setLoadingFetchData(false);
    });
  }, []);

  if (loadingFetchData) {
    return <div>loading...</div>;
  }

  return (
    <Container fluid className="bg-body-tertiary dark__bg-gray-1200">
      <div
        className="bg-holder bg-auth-card-overlay"
        style={{ backgroundImage: `url(${bg37})` }}
      />
      <Row className="flex-center position-relative min-vh-100 g-0 py-5">
        <Col xs={11} sm={10} xl={8}>
          <div className="p-2">
            <PageBreadcrumb items={resumeBreadcrumbItems} />
          </div>

          <Card className="border border-translucent auth-card">
            <Card.Body className="pe-md-0">
              <div id="dynamic" className="px-3 text-body-emphasis fs-6">
                {credits} Creditos
              </div>
              <div className="px-3 text-body-emphasis">
                <Link
                  to={!token ? '/signin' : '/resume/pricing'}
                  className="btn p-0 btn-link text-body order-1 order-lg-0"
                >
                  Adquirir mais Creditos
                </Link>
              </div>

              <Row className="align-items-center gx-0 gy-7">
                <Col
                  xs="auto"
                  className="bg-body-highlight dark__bg-gray-1100 rounded-3 position-relative overflow-hidden auth-title-box"
                >
                  <div
                    className="bg-holder"
                    style={{ backgroundImage: `url(${bg38})` }}
                  />
                  <div
                    className={classNames(
                      className,
                      'position-relative px-4 px-lg-7 py-7 pb-sm-5 text-center text-md-start pb-lg-7'
                    )}
                  >
                    <h3 className="mb-3 text-body-emphasis fs-7">
                      Currículos sendo Produzidos
                    </h3>
                    <p className="text-body-tertiary">
                      Não esquenta, vamos te avisar assim que seu currículo
                      estiver pronto! 😉
                    </p>
                    <ul
                      id="dynamic"
                      className="list-unstyled mb-0 w-max-content w-md-auto mx-auto"
                    >
                      {tasks.map((task, index) => (
                        <li
                          key={index}
                          className="d-flex align-items-center gap-2"
                        >
                          {task.status !== 'completed' &&
                          task.status !== 'failed' ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : null}
                          <span className="text-body-tertiary fw-semibold">
                            {task.status === 'failed' ? (
                              <>
                                {`❌ Erro na Produção - ${new Date(
                                  task.updatedAt
                                ).toLocaleDateString()}`}
                              </>
                            ) : task.status !== 'completed' ? (
                              `Produzindo - ${new Date(
                                task.updatedAt
                              ).toLocaleDateString()}`
                            ) : (
                              <a
                                href={`${process.env.REACT_APP_BACKEND_API_URL}/giga/mvp/user/resume/${task.externalReference}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                                download
                              >
                                {`✅ Baixar Currículo - ${new Date(
                                  task.updatedAt
                                ).toLocaleDateString()}`}
                              </a>
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="position-relative mb-6 d-none d-md-block text-center mt-md-15 z-n1">
                    <img
                      className="auth-title-box-img d-dark-none"
                      src={authIllustrations}
                      alt=""
                    />
                    <img
                      className="auth-title-box-img d-light-none"
                      src={authIllustrationsDark}
                      alt=""
                    />
                  </div>
                </Col>
                <Col className="mx-auto">
                  <div className="">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthCardLayout;
