import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';

interface FeatureSectionProps {
  link: string;
  isLast: boolean;
}

const ResultSection = ({ link, isLast }: FeatureSectionProps) => {
  return (
    <Row
      className={classNames('flex-between-center px-xl-11', {
        'mb-10 mb-md-9': !isLast
      })}
    >
      <Col md={11} className="mb-5 mb-md-0 text-center">
        <iframe width="1024" height="720" src={link} />
      </Col>
    </Row>
  );
};

export default ResultSection;
