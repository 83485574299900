/* eslint-disable no-case-declarations */
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { signIn } from 'aws-amplify/auth';
import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect } from 'aws-amplify/auth';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<unknown>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customState, setCustomState] = useState<string | null>(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signInWithRedirect':
          navigate('/');
          break;
        case 'signInWithRedirect_failure':
          setError('An error has occurred during the Oauth flow.');
          break;
        case 'customOAuthState':
          setCustomState(payload.data);
          break;
      }
    });

    return unsubscribe;
  }, []);

  function handleSignInClick() {
    signInWithRedirect({
      provider: 'Google'
    })
      .then(() => {
        console.log('Redirect for Google sign-in initiated successfully');
      })
      .catch(error => {
        console.error('Error during Google sign-in redirect:', error);
        setError(
          'An error occurred while trying to sign in with Google. Please try again.'
        );
        setShowToast(true);
      });
  }
  const handleLogin = async () => {
    try {
      console.log('Login');
      console.log(username);
      console.log(password);
      const { isSignedIn } = await signIn({
        username,
        password
      });
      if (isSignedIn) {
        navigate('/');
      } else {
        throw new Error('An error has occurred during the login process.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
      setShowToast(true);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };
  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Entrar</h3>
        <p className="text-body-tertiary">Acesse a sua conta</p>
      </div>
      <AuthSocialButtons onClick={handleSignInClick} title="Sign in" />
      <div className="position-relative">
        <hr className="bg-body-secondary mt-5 mb-4" />
        <div className="divider-content-center">ou use email</div>
      </div>
      <Form onKeyDown={handleKeyDown}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUsername(e.target.value)
              }
              id="email"
              type="email"
              className="form-icon-input"
              placeholder="nome@exemplo.com"
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Senha</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              id="password"
              type="password"
              className="form-icon-input"
              placeholder="Senha"
            />
            <FontAwesomeIcon
              icon={faKey}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Row className="flex-between-center mb-7">
          <Col xs="auto">
            <Form.Check type="checkbox" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                defaultChecked
              />
              <Form.Check.Label htmlFor="remember-me" className="mb-0">
                Continuar conectado
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            <Link
              to={`/pages/authentication/${layout}/forgot-password`}
              className="fs-9 fw-semibold"
            >
              Esqueci a senha
            </Link>
          </Col>
        </Row>
        <Button onClick={handleLogin} variant="primary" className="w-100 mb-3">
          Entrar
        </Button>
      </Form>

      <div className="text-center">
        <Link to={`/signup`} className="fs-9 fw-bold">
          Criar uma conta
        </Link>
      </div>
      <div
        className={`toast fade ${showToast ? 'show' : 'hide'}`}
        id="liveToast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <strong className="me-auto">Error Autenticação</strong>
          <small className="text-body-secondary">Agora</small>
          <button
            className="btn ms-2 p-0"
            type="button"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={() => setShowToast(false)}
          >
            <span className="uil uil-times fs-7"></span>
          </button>
        </div>
        <div className="toast-body">{errorMessage}</div>
      </div>
    </>
  );
};

export default SignInForm;
