import { Col, Row } from 'react-bootstrap';
import bg12 from 'assets/img/bg/bg-1-2.png';
import saas from 'assets/img/bg/funnel-saas.gif';
import bg23 from 'assets/img/bg/bg-23.png';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const ResumeHeroHeader = () => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);
  return (
    <section className="pb-8" id="home">
      <div className="container-small hero-header-container px-lg-7 px-xxl-3">
        <Row className="align-items-center" ref={sectionRef}>
          <Col
            xs={12}
            lg="auto"
            className="text-end order-0 order-md-1 order-1"
          >
            <div className="position-relative p-5 p-md-7 d-lg-none">
              <div
                className="bg-holder banner-bg"
                style={{
                  backgroundImage: `url(${bg23})`,
                  backgroundSize: 'contain'
                }}
              />
              <div className="position-relative">
                <img
                  className="w-100 shadow-lg d-dark-none rounded-2"
                  src={saas}
                  alt="hero-header"
                />
                <img
                  className="w-100 shadow-lg d-light-none rounded-2"
                  src={saas}
                  alt="hero-header"
                />
              </div>
            </div>
            <div className="hero-image-container position-absolute top-0 bottom-0 end-0 d-none d-lg-block">
              <div className="position-relative h-100 w-100">
                <div className="position-absolute h-100 top-0 d-flex align-items-center end-0 hero-image-container-bg">
                  <img
                    className="pt-7 pt-md-0 w-100"
                    src={bg12}
                    alt="hero-header"
                  />
                </div>
                <div className="position-absolute h-100 top-0 d-flex align-items-center end-0">
                  <img
                    className="pt-7 pt-md-0 w-100 shadow-lg d-dark-none rounded-2"
                    src={saas}
                    alt="hero-header"
                  />
                  <img
                    className="pt-7 pt-md-0 w-100 shadow-lg d-light-none rounded-2"
                    src={saas}
                    alt="hero-header"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="text-lg-start text-center pt-8 pb-6 order-0 position-relative"
          >
            <div>
              <span className="badge badge-phoenix badge-phoenix-success p-2 mb-3">
                <h6>
                  EXCLUSIVO PARA PROFISSIONAIS DE TECNOLOGIA <br /> EM BUSCA DE
                  EMPREGO INTERNACIONAL
                </h6>
              </span>
              <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-3 fs-xl-3 fw-black mb-4">
                Conquiste Seu Emprego Internacional em 3 Cliques:
                <br />
              </h1>
              <p className="mb-3 fs-7">
                <b>O Currículo que as maiores empresas querem ver: </b> <br />
                Imagine ter o currículo que as maiores empresas do mundo como
                Microsoft, Google e Facebook querem ver – sem perder horas
                formatando ou começando do zero.
              </p>
              <p className="mb-5 p-2">
                Com a nossa ferramenta, você cria um currículo internacional em
                minutos, importando suas informações diretamente do LinkedIn.
              </p>
              <p className="fs-6">
                <span className="badge badge-phoenix text-body-quaternary p-2 mb-3">
                  <b>Conquiste um emprego ganhando em dólar </b>
                  <br />
                </span>
              </p>
              <Link
                className="btn btn-lg btn-primary rounded-pill me-3"
                data-rr-ui-event-key="#cta"
                to={'/resume/ai'}
              >
                Crie meu currículo internacional agora
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ResumeHeroHeader;
