import { features } from 'data/landing/alternate-landing-data';
import ResultSection from './ResultSection';

const OurResults = () => {
  return (
    <section id="features" className="pb-10">
      <div className="container-small px-lg-7 px-xxl-3">
        <div className="text-center mb-10 mb-md-5">
          <h5 className="text-info mb-3">Resultados</h5>
          <h2 className="mb-3 lh-base">Nossos resultados falam por sí só</h2>
        </div>
        {features.map((feature, index) => (
          <ResultSection
            key={feature.id}
            link={feature.link}
            isLast={index === features.length - 1}
          />
        ))}
      </div>
    </section>
  );
};

export default OurResults;
