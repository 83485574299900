import React, { LegacyRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import bg13 from 'assets/img/bg/bg-13.png';
import bgRight21 from 'assets/img/bg/bg-right-21.png';
import bgLeft21 from 'assets/img/bg/bg-left-21.png';
import CountUp from 'react-countup';

const FunFacts = () => {
  return (
    <div className="pb-15">
      <div className="position-relative py-18">
        <div
          className="bg-holder z-1 world-map-bg"
          style={{
            transform: 'skew(0,-10deg)',
            backgroundImage: `url(${bg13})`
          }}
        />
        <div
          className="bg-holder z-2 opacity-25"
          style={{
            backgroundImage: `url(${bgRight21})`,
            backgroundSize: 'auto',
            backgroundPosition: 'right'
          }}
        />
        <div
          className="bg-holder z-2 mt-9 opacity-25"
          style={{
            backgroundImage: `url(${bgLeft21})`,
            backgroundSize: 'auto',
            backgroundPosition: 'left'
          }}
        />

        <section className="overflow-hidden z-2">
          <div
            className="container-small px-lg-7 px-xxl-3"
            data-bs-theme="light"
          >
            <div className="position-relative">
              <Row className="row mb-6">
                <div className="col-xl-8 text-center text-md-start">
                  <h2 className="text-white mb-2">
                    Programadores espalhados pelo
                  </h2>
                  <h1 className="fs-md-3 fs-xl-2 fw-black text-gradient-info text-uppercase mb-4 mb-md-0 text-color-white">
                    MUNDO
                  </h1>
                </div>
              </Row>

              <Row>
                <Col xl={12} className="text-center text-md-start mb-6 mb-xl-0">
                  <div className="d-md-flex justify-content-md-between">
                    <div className="mb-6 mb-md-0 me-4">
                      <CountUp
                        end={332}
                        duration={5}
                        suffix="h"
                        enableScrollSpy
                      >
                        {({ countUpRef }) => (
                          <div>
                            <h1
                              className="display-1 text-white fw-bolder"
                              ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                            />
                          </div>
                        )}
                      </CountUp>
                      <p className="text-white">
                        Todos os dias prática de inglês técnico para
                        programadores.
                        <br className="d-md-none d-lg-block" />
                      </p>
                    </div>
                    <div className="mb-6 mb-md-0 me-4">
                      <CountUp
                        end={120}
                        duration={3}
                        suffix="+"
                        enableScrollSpy
                      >
                        {({ countUpRef }) => (
                          <div>
                            <h1
                              className="display-1 text-white fw-bolder"
                              ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                            />
                          </div>
                        )}
                      </CountUp>
                      <p className="text-white">
                        Palavras e expressões técnicas são aprendidas e
                        praticadas por dia.
                        <br className="d-md-none d-lg-block" />
                      </p>
                    </div>
                    <div className="mb-6 mb-md-0 me-4">
                      <CountUp end={5} duration={1} enableScrollSpy>
                        {({ countUpRef }) => (
                          <div>
                            <h1
                              className="display-1 text-white fw-bolder"
                              ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                            />
                          </div>
                        )}
                      </CountUp>

                      <p className="text-white">
                        Nossos mentorados alcançaram posições em mais de cinco
                        países diferentes.
                        <br className="d-md-none d-lg-block" />
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FunFacts;
