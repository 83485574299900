import React from 'react';
import { Row, Col } from 'react-bootstrap';

const CtaIscaTypebot: React.FC = () => {
  return (
    <section>
      <div className="container">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <div className="pb-3">
              <h4>
                E Depois Agende Sua Análise de Carreira para Ter Acesso Ao Seu
                Plano Prático Individual para alcançar um emprego internacional.
              </h4>
            </div>
            <div
              id="typebot"
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://typebot.co/curriculo-ftk11fs" style="border: none; width: 100%; height: 600px"></iframe>`
              }}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CtaIscaTypebot;
