import CurriculoTrafbFeatures from 'components/modules/landing/default/features/CurriculoTrafbFeatures';
import CurriculoFooter from 'components/modules/landing/default/CurriculoFooter';
import CurriculoTrafbHeroHeader from 'components/modules/landing/default/CurriculoTrafbHeroHeader';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import CtaIscaTypebot from 'components/modules/showcase/CtaIscaTypebot';

const CurriculoTrafb = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });

  return (
    <div className="bg-body-emphasis">
      <CurriculoTrafbHeroHeader />
      <CurriculoTrafbFeatures />
      <CtaIscaTypebot />
      <CurriculoFooter />
    </div>
  );
};

export default CurriculoTrafb;
