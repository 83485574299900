import {
  useState,
  createContext,
  PropsWithChildren,
  useContext,
  useCallback
} from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

interface Task {
  action: string;
  category: string;
  createdAt: string;
  externalReference: string;
  status: string;
  updatedAt: string;
  userId: string;
}

interface ResumeServiceContextInterface {
  handleFetchProcessedResume: () => Promise<void>;
  handleUploadNewResume: (form: FormData) => Promise<void>;
  isSuccessVisible: boolean;
  isSpinnerVisible: boolean;
  token: string;
  tasks: Task[];
  credits: number | null;
}

export const ResumeServiceContext = createContext(
  {} as ResumeServiceContextInterface
);

const ResumeServiceProvider = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useState<string>('');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [credits, setCredits] = useState<number | null>(null);
  const [isSuccessVisible] = useState<boolean>(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState<boolean>(false);

  const handleFetchProcessedResume = useCallback(async () => {
    try {
      const session = await fetchAuthSession();
      const localToken = session?.tokens?.accessToken?.toString();
      setToken(localToken || '');
      if (!localToken || !session?.tokens?.idToken) {
        throw new Error('User not found');
      }
      const responseTokens = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/giga/mvp/user/tokens`,
        {
          headers: {
            Authorization: `Bearer ${localToken}`
          }
        }
      );
      const responseTasks = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/giga/mvp/user/tasks`,
        {
          headers: {
            Authorization: `Bearer ${localToken}`
          }
        }
      );
      const lastEightTasks = responseTasks.data;
      setCredits(responseTokens.data.tokenAmount);
      setTasks(lastEightTasks);
    } catch (error) {
      console.error('Error updating tasks & credits:', error);
    }
  }, [fetchAuthSession]);

  const handleUploadNewResume = useCallback(
    async (form: FormData) => {
      try {
        // Check if the user has enough tokens
        if (credits !== null && credits <= 1) {
          window.location.href = 'https://gigapros.io/resume/pricing'; // Redirect to checkout
          return; // Exit the function
        }
        setIsSpinnerVisible(true);
        const session = await fetchAuthSession();
        const localToken = session?.tokens?.accessToken?.toString();
        setToken(localToken || '');
        const apiURL = process.env.REACT_APP_BACKEND_API_URL;
        if (!localToken) {
          throw new Error('User not found');
        }
        const response = await axios.post(`${apiURL}/giga/mvp/resume/`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localToken}`
          },
          responseType: 'blob'
        });

        if (response.status === 200) {
          const intervalId = setInterval(async () => {
            const responseTasks = await axios.get(
              `${process.env.REACT_APP_BACKEND_API_URL}/giga/mvp/user/tasks/`,
              {
                headers: {
                  Authorization: `Bearer ${localToken}`
                }
              }
            );
            const lastEightTasks = responseTasks.data;
            setTasks(lastEightTasks);
          }, 10000);

          setTimeout(() => {
            clearInterval(intervalId);
          }, 60000);

          // setIsSuccessVisible(true);
          // setTimeout(() => {
          //   setIsSuccessVisible(false);
          //   // setCanSubmit(true);
          // }, 2500);
        }
      } catch (error) {
        console.error(
          `Error while uploading the resume to the server: `,
          error
        );
      } finally {
        // setCanSubmit(false);
        setIsSpinnerVisible(false);
      }
    },
    [fetchAuthSession]
  );

  return (
    <ResumeServiceContext.Provider
      value={{
        handleFetchProcessedResume,
        token,
        tasks,
        credits,
        handleUploadNewResume,
        isSuccessVisible,
        isSpinnerVisible
      }}
    >
      {children}
    </ResumeServiceContext.Provider>
  );
};

export const useResumeServiceContext = () => useContext(ResumeServiceContext);

export default ResumeServiceProvider;
