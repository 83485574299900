import { Col, Row } from 'react-bootstrap';
import { curriculoFeatures } from 'data/landing/default-landing-data';
import CurriculoFeatureSection from './CurriculoFeatureSection';

const CurriculoTrafbFeatures = () => {
  return (
    <section className="bg-body pt-15 pb-18" id="features">
      <div className="container-small px-lg-7 px-xxl-3">
        <div className="position-relative z-2">
          <Row className="mb-12">
            <Col lg={11} className="text-center text-lg-start pe-xxl-3">
              <h3 className="text-primary fw-bolder mb-4">
                Plano Prático e Materiais Direto ao Ponto
              </h3>
              <h4 className="mb-3 text-body-emphasis lh-base">
                Criados por Especialistas em Carreiras Internacionais que já
                ajudaram +300 desenvolvedores a conquistarem oportunidades em
                globais, alcançarem segurança financeira e terem uma melhor
                qualidade de vida.
              </h4>
            </Col>
          </Row>

          {curriculoFeatures.map((feature, index) => (
            <CurriculoFeatureSection
              key={feature.label}
              feature={feature}
              orderReverse={index === 1 || index === 3}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CurriculoTrafbFeatures;
