import { RouteObject, createBrowserRouter } from 'react-router-dom';
import App from 'App';
import Default from 'pages/pages/landing/Default';
import QuickBuild from 'pages/pages/resume/QuickBuild';
import SmallPitchClub from 'pages/pages/landing/SmallPitchClub';
import CurriculoTrafb from 'pages/pages/landing/CurriculoTrafb';
import Resume from 'pages/pages/landing/Resume';
import Gratitude from 'pages/pages/landing/Gratitude';
import ResumeThankYou from 'pages/pages/ResumeThankYou';
import ThankYouSchedulling from 'pages/pages/ThankYouSchedulling';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import MainLayout from 'layouts/MainLayout';
import GigaDashboardGeneral from 'pages/dashboard/giga/index';
import SignUp from 'pages/pages/authentication/giga/SignUp';
import SignIn from 'pages/pages/authentication/giga/SignIn';
import SignOut from 'pages/pages/authentication/giga/SignOut';
import ResetPassword from 'pages/pages/authentication/giga/ResetPassword';
import TwoFA from 'pages/pages/authentication/giga/TwoFA';
import { ProtectedRoute } from 'components/ProtectedRoute';
import ResumePricing from 'pages/pages/resume/pricing/ResumePricing';
//https://blog.logrocket.com/authentication-react-router-v6/

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        index: true,
        path: '/',
        element: <Default />
      },
      {
        path: '/resume',
        element: <Resume />
      },
      {
        path: '/resume/ai',
        element: <QuickBuild />
      },
      {
        path: '/resume/pricing',
        element: <ResumePricing />
      },
      {
        path: '/resume/thank-you',
        element: <ResumeThankYou />
      },
      {
        path: '/curriculo-trafb',
        element: <CurriculoTrafb />
      },
      {
        path: '/pitch/small/club',
        element: <SmallPitchClub />
      },
      {
        path: '/thank-you',
        element: <Gratitude />
      },
      {
        path: '/thank-you-schedulling',
        element: <ThankYouSchedulling />
      },
      {
        path: '/signup',
        element: <SignUp />
      },
      {
        path: '/signin',
        element: <SignIn />
      },
      {
        path: '/signout',
        element: <SignOut />
      },
      {
        path: '/reset-password',
        element: <ResetPassword />
      },
      {
        path: '/twofa',
        element: <TwoFA />
      },
      {
        path: '/dashboard',
        element: (
          <ProtectedRoute>
            <MainLayoutProvider>
              <MainLayout />
            </MainLayoutProvider>
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <GigaDashboardGeneral />
          }
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
