import illustration22 from 'assets/img/spot-illustrations/22_2.png';
import illustration22dark from 'assets/img/spot-illustrations/dark_22.png';
import illustration30 from 'assets/img/spot-illustrations/30.png';
import illustration33 from 'assets/img/spot-illustrations/16.png';
import illustration23dark from 'assets/img/spot-illustrations/dark_23.png';
import illustration15 from 'assets/img/spot-illustrations/15.png';
import illustration24dark from 'assets/img/spot-illustrations/dark_24.png';
import blog1 from 'assets/img/blog/blog-1.png';
import blog2 from 'assets/img/blog/blog-2.png';
import blog3 from 'assets/img/blog/blog-3.png';
import blog4 from 'assets/img/blog/blog-4.png';

export type Feature = {
  lightImage: string;
  darkImage: string;
  label: string;
  title: string;
  details: string;
  link: string;
};

export type Blog = {
  id: number;
  image: string;
  views: number;
  likes: number;
  comments: number;
  category: string;
  title: string;
};

export const defaultFeatures: Feature[] = [
  {
    lightImage: illustration22,
    darkImage: illustration22dark,
    label: 'Entrevistas',
    title: 'Entrevistas técnicas com revisão.',
    details:
      'Vamos praticar e manter sempre a atenção nos pequenos deslizes que podem ocorrer durante as simulações, com as correções sendo feitas ao final do encontro.',
    link: '#!'
  },
  {
    lightImage: illustration30,
    darkImage: illustration23dark,
    label: 'Comunidade Sênior',
    title:
      'Conecte-se com outros profissionais com o mesmo objetivo que o seu.',
    details:
      'Realizamos reuniões de segunda a sexta para discutir tecnologia, junto com convidados de várias nacionalidades e áreas de especialização.',
    link: '#!'
  },
  {
    lightImage: illustration15,
    darkImage: illustration24dark,
    label: 'Relatórios',
    title: 'Análise do seu desempenho e crescimento.',
    details:
      'Acompanhe seu  progresso na plataforma com a ajuda de nossos mentores e tecnologia.',
    link: '#!'
  },
  {
    lightImage: illustration33,
    darkImage: illustration24dark,
    label: 'I.A',
    title: 'Plataforma com Inteligência Artificial.',
    details:
      'Prepare-se para entrevistas praticando com nosso aplicativo, que te dará feedback em tempo real.',
    link: '#!'
  }
];

export const resumeFeatures: Feature[] = [
  {
    lightImage: illustration15,
    darkImage: illustration15,
    label: 'Plano de Ação',
    title: 'Currículo Internacional em 3 Cliques',
    details: 'Baseado nos padrões das gigantes da tecnologia.',
    link: '#!'
  },
  {
    lightImage: illustration30,
    darkImage: illustration23dark,
    label: 'Aprenda com quem já conquistou',
    title: 'Importação Fácil do LinkedIn',
    details:
      'Economize tempo e deixe a ferramenta fazer o trabalho duro por você.',
    link: '#!'
  },
  {
    lightImage: illustration22,
    darkImage: illustration22dark,
    label: 'Currículo',
    title: 'Design Profissional e Validado',
    details:
      'Formato otimizado pelas empresas mais exigentes do mercado global.',
    link: '#!'
  },
  {
    lightImage: illustration33,
    darkImage: illustration24dark,
    label: 'eBook',
    title: 'Feito para profissionais de tecnologia sem tempo a perder',
    details:
      'Se você está pronto para dar o próximo passo na sua carreira internacional e quer uma solução eficiente, essa oferta foi feita para você.',
    link: '#!'
  }
];
export const curriculoFeatures: Feature[] = [
  {
    lightImage: illustration15,
    darkImage: illustration15,
    label: 'Plano de Ação',
    title: 'Consultoria de Carreira Individual com Consultor Exclusivo',
    details:
      '45 minutos de Consultoria de Carreira com o nosso time de especialistas que vão criar um plano prático para te ajudar a se preparar para entrevistas e se destacar no mercado internacional, de forma 100% segura.',
    link: '#!'
  },
  {
    lightImage: illustration30,
    darkImage: illustration23dark,
    label: 'Aprenda com quem já conquistou',
    title: 'Casos de Sucesso: Inspire-se com Alisson Bouchinhas',
    details:
      'Hora de conhecer outros profissionais como você que conquistaram oportunidades internacionais e estão vivendo seus sonhos! Casos Reais de Transformações Inspiradoras.',
    link: '#!'
  },
  {
    lightImage: illustration22,
    darkImage: illustration22dark,
    label: 'Currículo',
    title: 'Modelo de Currículo Internacional',
    details:
      'Um modelo de currículo que irá te ajudar a passar por todas pré-avaliações internacionais.',
    link: '#!'
  },
  {
    lightImage: illustration33,
    darkImage: illustration24dark,
    label: 'eBook',
    title:
      'Ebook - O Guia Definitivo para Entrevistas Internacionais: Como Se Preparar e Conseguir a Vaga dos Seus Sonhos.',
    details:
      'O Guia prático e comprovado da metodologia que tem transformado a carreira de +300 desenvolvedores. Uma leitura rápida, porém extremamente útil.',
    link: '#!'
  }
];
export const blogs: Blog[] = [
  {
    id: 1,
    image: blog1,
    views: 2563,
    likes: 125,
    comments: 125,
    category: 'SEO',
    title: 'Top 10 ways to Ace SEO for your business'
  },
  {
    id: 2,
    image: blog2,
    views: 1256,
    likes: 325,
    comments: 32,
    category: 'Marketing',
    title: 'Top 12 Marketing strategies you can take'
  },
  {
    id: 3,
    image: blog3,
    views: 142,
    likes: 123,
    comments: 22,
    category: 'Marketing',
    title: 'The top 7 methods to improve as a marketer'
  },
  {
    id: 4,
    image: blog4,
    views: 2563,
    likes: 325,
    comments: 112,
    category: 'Tech',
    title: 'Best places for a tech job in U.S'
  }
];
