import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import rdstation from 'assets/img/brands/rd-station.svg';
import wallmart from 'assets/img/brands/wallmart.png';
import dmg from 'assets/img/brands/dmg.jpg';
import ifood from 'assets/img/brands/ifood.png';
import magalu from 'assets/img/brands/magalu.png';
import itau from 'assets/img/brands/itau.png';
import hapvida from 'assets/img/brands/hapvida.png';
import jbs from 'assets/img/brands/jbs.png';

const Brand = ({ image, className }: { image: string; className?: string }) => {
  return (
    <div
      className={classNames(
        className,
        'p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent'
      )}
    >
      <img src={image} alt="" className="w-100" />
    </div>
  );
};

const Brands = ({ className }: { className?: string }) => {
  return (
    <section className={className}>
      <div className="container-small px-lg-7 px-xxl-3">
        <h3 className="mb-6 lh-sm text-center text-body-quaternary">
          Aqui estão algumas das empresas onde nossos alunos trabalham.
        </h3>
        <Row className="g-0">
          <Col xs={6} md={3}>
            <Brand
              image={wallmart}
              className="border-bottom border-end-md border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={rdstation}
              className="border-bottom border-end border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={dmg}
              className="border-bottom border-end border-end-md border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={ifood}
              className="border-bottom border-end-lg-0 border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={magalu}
              className="border-end border-bottom border-bottom-md-0 border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={itau}
              className="border-end-md border-bottom border-bottom-md-0 border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand image={hapvida} className="border-end border-translucent" />
          </Col>
          <Col xs={6} md={3}>
            <Brand image={jbs} className="border-end-lg-0 border-translucent" />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Brands;
