import Footer from 'components/modules/landing/alternate/Footer';
import PitchSmallHeader from 'components/modules/landing/alternate/PitchSmallHeader';
import OurResults from 'components/modules/landing/alternate/features/OurResults';
import PricingTrial from 'components/modules/landing/alternate/pricing/PricingTrial';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';

const SmallPitchClub = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });
  return (
    <div className="bg-body-emphasis dark__bg-gray-1200">
      <PitchSmallHeader />
      <OurResults />
      <PricingTrial />
      <Footer />
    </div>
  );
};

export default SmallPitchClub;
