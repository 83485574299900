import { Col, Row } from 'react-bootstrap';
import alisson from 'assets/img/team/alisson.png';
import bg2 from 'assets/img/bg/bg-2.png';
import marcel from 'assets/img/team/marcel.png';
import carlos from 'assets/img/team/carlos.jpg';
import tiago from 'assets/img/team/tiago.jpg';
import Rating from 'components/base/Rating';
const Brands = () => {
  return (
    <section className="bg-body pt-15 pb-18">
      <div className="container-small">
        <Row className="row gx-10 text-justify">
          <Col className="border p-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3>Alisson Boucinhas</h3>
                <h4 className="text-primary fw-bolder mb-3">
                  Senior Software Engineer
                </h4>
                <Rating
                  readonly
                  fillIconColor="primary"
                  emptyIconColor="primary-light"
                />
              </div>
              <div className="testimonial-avatar-container d-inline-block position-relative">
                <div
                  className="bg-holder"
                  style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'contain'
                  }}
                />
                <img
                  src={alisson}
                  alt=""
                  height={103}
                  width={103}
                  className="rounded-3 mb-lg-0 opacity-100 position-relative"
                />
              </div>
            </div>
            <p>
              Passei muito tempo fazendo aulas de inglês e achando que isso era
              o suficiente para me preparar para vagas internacionais. Fazia
              várias entrevistas e não conseguia me destacar e eu não sabia o
              motivo. Após a Giga eu me dei conta de que o meu problema era
              praticar o que era realmente relevante para passar nos processos
              seletivos: conversas técnicas em inglês, desafios de código a
              estilo live coding e simulações de entrevistas. Logo do meu
              primeiro mês já passei em um processo pra uma vaga temporária em
              uma empresa americana. Após 6 meses de prática na Giga consegui
              finalmente uma oportunidade com contrato sem definição de data
              final. Foram basicamente 6 meses para sair de um nível onde eu
              travava em todas as entrevistas até chegar na minha oportunidade
              ganhando mais de 4k de dólares. Isso só foi possível pela prática
              diária e aplicando esforço no que realmente me fez passar nos
              seletivos. Consegui em 6 meses de Giga o que não tinha conseguido
              em quase 2 anos sozinho.
            </p>
          </Col>
          <Col className="border p-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3>Carlos Cavalcanti</h3>
                <h4 className="text-primary fw-bolder mb-3">
                  Senior Software Engineer
                </h4>
                <Rating
                  readonly
                  fillIconColor="primary"
                  emptyIconColor="primary-light"
                />
              </div>
              <div className="testimonial-avatar-container d-inline-block position-relative">
                <div
                  className="bg-holder"
                  style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'contain'
                  }}
                />
                <img
                  src={carlos}
                  alt=""
                  height={103}
                  width={103}
                  className="rounded-3 mb-lg-0 opacity-100 position-relative"
                />
              </div>
            </div>
            <p>
              Pra quem busca uma oportunidade no exterior, é preciso ter duas
              coisas: inglês e conhecimento técnico. Um dos pontos cruciais, ao
              meu ver, é a frequência e constância que a Giga Pros consegue
              proporcionar aos seus membros. São encontros diários e dinâmicos,
              focados no que REALMENTE importa para conseguir um trabalho
              internacional. Os temas dos encontros, além de interessantes, são
              importantes para o nosso desenvolvimento da língua inglesa e do
              conhecimento técnico. Estava em busca de uma mentoria que pudesse
              me ajudar, eu posso dizer com TODA CERTEZA que é a melhor mentoria
              que encontrei. Pesquisei bastante, mas não encontrei nada que
              entregasse tanto quanto a Giga Pros.
            </p>
          </Col>
        </Row>
        <Row className="row gx-10">
          <Col className="border p-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3>Tiago Trindade Stangarlin</h3>
                <h4 className="text-primary fw-bolder mb-3">
                  Software Engineer
                </h4>
                <Rating
                  readonly
                  fillIconColor="primary"
                  emptyIconColor="primary-light"
                />
              </div>
              <div className="testimonial-avatar-container d-inline-block position-relative">
                <div
                  className="bg-holder"
                  style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'contain'
                  }}
                />
                <img
                  src={tiago}
                  alt=""
                  height={103}
                  width={103}
                  className="rounded-3 mb-lg-0 opacity-100 position-relative"
                />
              </div>
            </div>
            <p>
              The classes are very practical and focused on the specific needs
              of the sector, covering everything from technical vocabulary to
              interview simulations. Additionally, the instructors are extremely
              qualified and helpful, always ready to clarify doubts and provide
              feedback. I feel that I am gaining the confidence and vocabulary
              necessary to stand out in interviews and advance my career. I
              highly recommend this course to anyone looking to better prepare
              for the IT job market. This course has been an essential tool in
              my professional journey.
            </p>
          </Col>
          <Col className="border p-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3>Marcel Brasileiro</h3>
                <h4 className="text-primary fw-bolder mb-3">
                  Analista de Desenvolvimento de Sistemas
                </h4>
                <Rating
                  readonly
                  fillIconColor="primary"
                  emptyIconColor="primary-light"
                />
              </div>
              <div className="testimonial-avatar-container d-inline-block position-relative">
                <div
                  className="bg-holder"
                  style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundSize: 'contain'
                  }}
                />
                <img
                  src={marcel}
                  alt=""
                  height={103}
                  width={103}
                  className="rounded-3 mb-lg-0 opacity-100 position-relative"
                />
              </div>
            </div>
            <p>
              Desde que comecei o curso, notei uma melhora contínua e constante.
              A cada semana, fico cada vez mais confiante e confortável em falar
              inglês. Os feedbacks que tenho recebido sobre minha capacidade de
              falar têm sido excelentes, e hoje sinto que o inglês não é um
              impedimento para trabalhar em empresas internacionais, com times
              que se comunicam apenas em inglês. A Giga me deu a oportunidade de
              praticar e me ensinou a dominar um conhecimento que eu vinha
              acumulando por anos com diferentes cursos de inglês, mas nenhum
              deles foi capaz de trazer a confiança na fala como a Giga pôde me
              proporcionar.
            </p>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Brands;
