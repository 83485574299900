import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { signUp, signInWithRedirect } from 'aws-amplify/auth';
import { useState } from 'react';

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const handleGoogleSignUp = async () => {
    try {
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error('Error signing up with Google:', error);
    }
  };
  const handleRegister = async () => {
    try {
      console.log(password);
      console.log(email);
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email
          }
        }
      });

      navigate('/twofa');
    } catch (error) {
      console.error('error signing up:', error);
    }
  };
  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Cadastre-se</h3>
        <p className="text-body-tertiary">Crie a sua conta agora</p>
      </div>
      <AuthSocialButtons onClick={handleGoogleSignUp} title="Sign up" />
      <div className="position-relative mt-4">
        <hr className="bg-body-secondary" />
        <div className="divider-content-center">ou use email</div>
      </div>
      <Form>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            id="email"
            type="email"
            placeholder="nome@exemplo.com"
          />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Senha</Form.Label>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                id="password"
                type="text"
                placeholder="Senha"
              />
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
                Confirme a senha
              </Form.Label>
              <Form.Control
                id="confirmPassword"
                type="text"
                placeholder="Confirme a Senha"
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            name="termsService"
            id="termsService"
          />
          <Form.Check.Label
            htmlFor="termsService"
            className="fs-9 text-transform-none"
          >
            Eu aceito os <Link to="#!">termos de uso </Link>e{' '}
            <Link to="#!">política de privacidade</Link>
          </Form.Check.Label>
        </Form.Check>
        <Button
          onClick={handleRegister}
          variant="primary"
          className="w-100 mb-3"
        >
          Cadastre-se
        </Button>
        <div className="text-center">
          <Link to={`/signin`} className="fs-9 fw-bold">
            Entre com uma conta existente
          </Link>
        </div>
      </Form>
    </>
  );
};

export default SignUpForm;
