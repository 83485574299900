import alisson from 'assets/img/team/alisson.png';
import marcel from 'assets/img/team/marcel.png';
import team12 from 'assets/img/team/12.webp';
import team13 from 'assets/img/team/13.webp';
import team14 from 'assets/img/team/14.webp';

export type Testimonial = {
  id: number;
  name: string;
  avatar: string;
  occupation: string;
  comment: string;
  rating: number;
};

export const testimonials: Testimonial[] = [
  {
    id: 1,
    avatar: marcel,
    rating: 5,
    comment:
      'Sou aluno da Giga e minha comunicação está melhorando de forma exponencial... Vale todo o investimento!',
    name: 'Marcel Brasileiro',
    occupation: 'Senior React Developer '
  },
  {
    id: 2,
    avatar: alisson,
    rating: 5,
    comment:
      'A melhor mentoria para qualquer Engenheiro de Software que quer conquistar uma vaga internacional!',
    name: 'Alisson Boucinhas',
    occupation: 'Software Engineer at iFood'
  }
];

export const alternateTestimonial: Testimonial[] = [
  {
    id: 1,
    name: 'Carlos Cavalcanti',
    occupation: 'Sênior Software Engineer',
    comment:
      'Pra quem busca uma oportunidade no exterior, é preciso ter duas coisas: inglês e conhecimento técnico. Um dos pontos cruciais, ao meu ver, é a frequência e constância que a Giga Pros consegue proporcionar aos seus membros. São encontros diários e dinâmicos, focados no que REALMENTE importa para conseguir um trabalho internacional. Os temas dos encontros, além de interessantes, são importantes para o nosso desenvolvimento da língua inglesa e do conhecimento técnico. Estava em busca de uma mentoria que pudesse me ajudar, eu posso dizer com TODA CERTEZA que é a melhor mentoria que encontrei. Pesquisei bastante, mas não encontrei nada que entregasse tanto quanto a Giga Pros.',
    avatar: team12,
    rating: 5
  },
  {
    id: 2,
    name: 'Alisson Boucinhas',
    occupation: 'Senior Software Engineer',
    comment:
      'Passei muito tempo fazendo aulas de inglês e achando que isso era o suficiente para me preparar para vagas internacionais. Fazia várias entrevistas e não conseguia me destacar e eu não sabia o motivo. Após a Giga eu me dei conta de que o meu problema era praticar o que era realmente relevante para passar nos processos seletivos: conversas técnicas em inglês, desafios de código a estilo live coding e simulações de entrevistas. Logo do meu primeiro mês já passei em um processo pra uma vaga temporária em uma empresa americana. Após 6 meses de prática na Giga consegui finalmente uma oportunidade com contrato sem definição de data final. Foram basicamente 6 meses para sair de um nível onde eu travava em todas as entrevistas até chegar na minha oportunidade ganhando mais de 4k de dólares. Isso só foi possível pela prática diária e aplicando esforço no que realmente me fez passar nos seletivos. Consegui em 6 meses de Giga o que não tinha conseguido em quase 2 anos sozinho.',
    avatar: team13,
    rating: 4.5
  },
  {
    id: 3,
    name: 'TIAGO TRINDADE STANGARLIN',
    occupation: 'CTO',
    comment:
      'The classes are very practical and focused on the specific needs of the sector, covering everything from technical vocabulary to interview simulations. Additionally, the instructors are extremely qualified and helpful, always ready to clarify doubts and provide feedback. I feel that I am gaining the confidence and vocabulary necessary to stand out in interviews and advance my career. I highly recommend this course to anyone looking to better prepare for the IT job market. This course has been an essential tool in my professional journey.',
    avatar: team14,
    rating: 4
  }
];
