import { Navigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';

// eslint-disable-next-line react/prop-types
export const ProtectedRoute = ({ children }) => {
  const user = getCurrentUser();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/signin" />;
  }
  return children;
};
