import { Col, Row } from 'react-bootstrap';
import EcomStats from 'components/stats/EcomStats';
import EcomTotalSells from 'components/modules/e-commerce/dashboard/EcomTotalSells';
import LeadConversion from 'components/modules/crm/LeadConversion';
import RevenueTarget from 'components/modules/crm/RevenueTarget';
import IssuesDiscovered from 'components/modules/project-management/dashboard/IssuesDiscovered';
const GigaDashboardGeneral = () => {
  return (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <Col xs={12} xxl={6}>
            <div className="mb-8">
              <h2 className="mb-2">Painel de Métricas Resumido</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Visão geral do que está acontecendo nos departamentos de Vendas,
                Operação e Finanças.
              </h5>
            </div>
            <Row className="g-3 mb-4">
              <h3>Assinaturas</h3>
            </Row>
            <EcomStats />
            <hr className="bg-body-secondary mb-6 mt-4" />
            <EcomTotalSells />
          </Col>
          <Col xs={12} xxl={6}>
            <Row className="g-3">
              <Col xs={12} md={12}>
                <LeadConversion />
              </Col>
              <Col xs={12} md={12}>
                <RevenueTarget />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="g-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-6 pb-3 mt-6">
          <IssuesDiscovered />
        </Row>
      </div>
    </>
  );
};

export default GigaDashboardGeneral;
