import { Col, Row, Stack } from 'react-bootstrap';
import feature from 'assets/img/spot-illustrations/30.png';
import { useEffect } from 'react';
import gustavo from 'assets/img/team/gustavo-2.png';
import marcos from 'assets/img/team/marcos-2.png';
import giga from 'assets/img/team/giga-logo-alt.png';
import bg21 from 'assets/img/bg/bg-21.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const data = [
  {
    index: 1,
    name: 'GIGA PROS',
    image: giga,
    designation: 'Internacionalização de Carreiras',
    twitter: 'https://x.com/gigaprosclub',
    instagram: 'https://www.instagram.com/gigapros.club/'
  },
  {
    index: 2,
    name: 'Marcos Kanagawa',
    image: marcos,
    designation: 'Founder',
    twitter: 'https://x.com/kanagawaMarcos/',
    instagram: 'https://www.instagram.com/kanagawamarcos/'
  },
  {
    index: 3,
    name: 'Gustavo Braga',
    image: gustavo,
    designation: 'CoFounder',
    twitter: '',
    instagram: 'https://www.instagram.com/tavobraga/'
  }
];
const ThankYouSchedulling = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = "fbq('track', 'Schedule');";
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Stack className="flex-center content-min-h">
      <div className="text-center py-9">
        <img
          src={feature}
          width={180}
          className="img-fluid mb-7 d-dark-none"
          alt="starter"
        />
        <img
          src={feature}
          width={180}
          className="img-fluid mb-7 d-light-none"
          alt="starter"
        />
        <h1 className="text-body-secondary fw-normal mb-5">
          Agendamento concluído! Nos vemos lá!
        </h1>
        <h3 className="text-body-secondary fw-normal mb-5">
          Pra acompanhar nosso trabalho, segue a gente no Instagram{' '}
        </h3>
        <Row className="justify-content-center">
          {data.map(member => (
            <Col key={member.index} md={4} className="text-center mb-4">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: `url(${bg21})`,
                      backgroundSize: 'contain'
                    }}
                  />

                  <a
                    href={member.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid rounded mb-3 position-relative"
                      src={member.image}
                      alt={member.name}
                    />
                  </a>
                </div>
                <a
                  href={member.instagram}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4>{member.name}</h4>
                </a>
                <h6 className="mb-3 ">{member.designation}</h6>
                <div>
                  <a href={member.instagram} className="text-primary me-3">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href={member.twitter} className="text-primary">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Stack>
  );
};

export default ThankYouSchedulling;
