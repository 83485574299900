/* eslint-disable @typescript-eslint/no-namespace */

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const ResumePricing = () => {
  return (
    <div className="p-3">
      <stripe-pricing-table
        pricing-table-id="prctbl_1PwFWEFGm3VCRpVddHwYkynn"
        publishable-key="pk_live_51OteuuFGm3VCRpVdO4ALF5JWcehul4Yd4ZbKJSo3KRnwZiL2mNxqKlPyHfRUC9K1rTS5JeGRpiIjKhgyI8cBFuNH00NmHuBpPn"
      ></stripe-pricing-table>
    </div>
  );
};

export default ResumePricing;
