import ResumeFeatures from 'components/modules/landing/default/features/ResumeFeatures';
import CurriculoFooter from 'components/modules/landing/default/CurriculoFooter';
import ResumeHeroHeader from 'components/modules/landing/default/ResumeHeroHeader';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import DefaultLandingNavbar from 'components/navbars/default-landing-navbar/DefaultLandingNavbar';
import FacebookPixel from 'FacebookPixel';

const Resume = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });

  return (
    <div className="bg-body-emphasis">
      <FacebookPixel event="PageView" />
      <DefaultLandingNavbar />
      <ResumeHeroHeader />
      <ResumeFeatures />
      <CurriculoFooter />
    </div>
  );
};

export default Resume;
