import { Col, Row } from 'react-bootstrap';

const PitchSmallHeader = () => {
  return (
    <section id="home" className=" overflow-hidden">
      <div className="hero-header-container-alternate position-relative">
        <div className="container-small px-lg-7 px-xxl-3">
          <Row className="align-items-center">
            <Col
              lg={12}
              className="pt-8 pb-6 position-relative z-5 text-center text-lg-center"
            >
              <h1 className="fs-12 fs-md-1 fs-xl-1 fw-black mb-4">
                Como a <span className="text-primary me-3">GIGA</span> garante a
                sua vaga internacional
              </h1>
              <h3 className="mb-6 lh-sm text-center text-body-quaternary">
                Aqui estão algumas das empresas onde nossos alunos trabalham.
              </h3>
              <iframe
                width="1024"
                height="720"
                src="https://www.youtube.com/embed/rbhYBBax0CI/"
              />
            </Col>
          </Row>
        </div>
        <div className="container-small px-md-8 mb-8 d-lg-none">
          <div className="position-relative">
            <iframe
              width="420"
              height="315"
              src="https://www.youtube.com/embed/rbhYBBax0CI/"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PitchSmallHeader;
