import { Col, Row } from 'react-bootstrap';
import { resumeFeatures } from 'data/landing/default-landing-data';
import CurriculoFeatureSection from './CurriculoFeatureSection';

const CurriculoTrafbFeatures = () => {
  return (
    <section className="bg-body pt-15 pb-18" id="features">
      <div className="container-small px-lg-7 px-xxl-3">
        <div className="position-relative z-2">
          <Row className="mb-12">
            <Col lg={11} className="text-center text-lg-start pe-xxl-3">
              <h3 className="text-primary fw-bolder mb-4">
                Por que desperdiçar tempo criando um currículo do zero?
              </h3>
              <h4 className="mb-3 text-body-emphasis lh-base">
                Você sabe o quão competitivo o mercado internacional é. Cada
                detalhe importa, e um currículo de alta qualidade pode ser a
                diferença entre ser contratado ou ser ignorado. E se você
                pudesse entregar exatamente o que as maiores empresas querem,
                sem o esforço e sem erro?
              </h4>
            </Col>
          </Row>

          {resumeFeatures.map((feature, index) => (
            <CurriculoFeatureSection
              key={feature.label}
              feature={feature}
              orderReverse={index === 1 || index === 3}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CurriculoTrafbFeatures;
