import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';

const GratitudeMain = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = "fbq('track', 'Lead');";
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
    <section className="pb-12" id="home">
      <div className="container-small">
        <div className="text-center">
          <div>
            <span className="badge badge-phoenix badge-phoenix-success rounded-pill p-2 mb-3 w-50">
              <h6>
                Enviamos o seu currículo e os materiais para o seu email e
                whatsapp!
              </h6>
            </span>
            <h3 className="fs-6 fw-black mb-4">
              Mas, não se esqueça de AGENDAR SUA ANÁLISE DE CARREIRA 100%
              GRATUITA com um de nossos especialistas para receber um plano
              personalizado para você de como consquitar uma vaga no exterior.
            </h3>
            <p className="mb-3 fs-8">
              * As vagas são limitadas e sujeitas à disponibilidade de horários.
            </p>
            <div className="progress mt-6 mb-6 h-50 w-50 mx-auto br-50">
              <div className="progress-bar w-75 p-2 btn-info">
                87% das vagas já foram preenchidas!
              </div>
            </div>
            <Link
              className="btn btn-lg btn-primary me-3"
              data-rr-ui-event-key="#cta"
              onClick={e => {
                e.preventDefault();
                window.location.href =
                  'https://tidycal.com/gigapros/sessao-estrategica-resume';
              }}
              to={'#cta'}
            >
              Quero agendar minha análise de carreira gratuita!
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GratitudeMain;
