import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/img/icons/logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <section data-bs-theme="dark" className="bg-dark dark__bg-gray-1000">
      <div className="container-small px-lg-7 px-xxl-3">
        <Row className="gx-xxl-8 gy-5 align-items-center mb-5">
          <Col xl="auto" className="text-center">
            <Link to="/">
              <img src={logo} alt="" height={48} />
            </Link>
          </Col>
          <Col xl="auto">
            <div className="d-flex align-items-center justify-content-center gap-8">
              <Link to="#!" className="text-white">
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
              <Link to="#!" className="text-white">
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
              <Link to="#!" className="text-white">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </Link>
            </div>
          </Col>
        </Row>
        <hr className="border-top" />
        <div className="d-sm-flex flex-between-center text-center">
          <p className="text-body-tertiary mb-0">
            Todos Direitos Reservados. CNPJ: 51.387.812/0001-72 Endereço: Av.
            dos Holandeses/Cons. Hilton Rodrigues, Parq/Atlantico, Número 15,
            Olho D'Agua, 65.065-180, São Luís - MA Contatos: +55 (11) 97484-9856
            (Atendimento) +55 (11) 94279-1230 (Atendimento) +55 (98) 3011-6152
            (Recepção) Email: contatodagiga@gmail.com
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
