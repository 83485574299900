import { Stack } from 'react-bootstrap';
import starterImg from 'assets/img/spot-illustrations/2.png';
import starterDarkImg from 'assets/img/spot-illustrations/dark_2.png';
import Button from 'components/base/Button';
import { Link } from 'react-router-dom';
import Dropzone from 'components/base/Dropzone';
import { useState } from 'react';
import ResumeCardLayout from 'layouts/ResumeCardLayout';
import { useResumeServiceContext } from 'providers/ResumeServiceProvider';

const QuickBuild = () => {
  const [file, setFile] = useState<File | null>(null);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const { token, handleUploadNewResume, isSpinnerVisible, isSuccessVisible } =
    useResumeServiceContext();

  const handleFileDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
    setCanSubmit(true);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    Notification.requestPermission();
    const form = new FormData();
    if (file) {
      form.append('file', file);
    }
    await handleUploadNewResume(form);
    setCanSubmit(true);
  };

  return (
    <ResumeCardLayout className="pb-md-7">
      <Stack className="flex-center content-min-h">
        <div className="text-center py-9">
          <img
            src={starterImg}
            width={236}
            className="img-fluid mb-7 d-dark-none"
            alt="starter"
          />
          <img
            src={starterDarkImg}
            width={236}
            className="img-fluid mb-7 d-light-none"
            alt="starter"
          />
          <h2 className="text-body-secondary fw-normal mb-5">
            Importe o seu curriculo atual
          </h2>
          <div className="mb-5">
            <h6 className="mb-3">Dica: Você pode usar o seu linkedin.</h6>
            <Dropzone className="mb-3" onDrop={handleFileDrop} />
          </div>
          <div
            id="success"
            className="alert alert-phoenix-success"
            role="alert"
            style={{ display: isSuccessVisible ? 'block' : 'none' }}
          >
            Estamos gerando o seu curriculo internacional!
          </div>
          <div className="position-relative">
            <div
              id="loading"
              className="spinner-border position-absolute top-50 start-50"
              role="status"
              style={{ display: isSpinnerVisible ? 'block' : 'none' }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          {!isSpinnerVisible && !isSuccessVisible && (
            <Button
              as={Link}
              className={
                'btn btn-lg btn-primary ' +
                (canSubmit && token ? '' : 'disabled')
              }
              onClick={handleSubmit}
            >
              Criar meu Curriculo Internacional
            </Button>
          )}
        </div>
      </Stack>
    </ResumeCardLayout>
  );
};

export default QuickBuild;
