import { Row, Col } from 'react-bootstrap';
import logoWhite from 'assets/img/icons/logo-white.png';
import bg19 from 'assets/img/bg/bg-19.png';
import bgRight20 from 'assets/img/bg/bg-right-20.png';
import bgLeft20 from 'assets/img/bg/bg-left-20.png';
import { Link } from 'react-router-dom';
import { CSSProperties } from 'react';
import classNames from 'classnames';

const FooterList = ({
  label,
  items,
  className
}: {
  label: string;
  items: string[];
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        className,
        'border-dashed border-start border-primary-light ps-3'
      )}
      style={{ '--phoenix-border-opacity': '.2' } as CSSProperties}
    >
      <h5 className="lh-lg fw-bolder mb-2 text-light">{label}</h5>
      <ul className="list-unstyled mb-1">
        {items.map((item, index) => (
          <li className="mb-1" key={index}>
            <Link
              className="text-body-quaternary"
              data-bs-theme="light"
              to="#!"
            >
              {item}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CurriculoFooter = () => {
  return (
    <div className="position-relative">
      <div
        className="bg-holder footer-bg"
        style={{ backgroundImage: `url(${bg19})`, backgroundSize: 'auto' }}
      />
      <div
        className="bg-holder"
        style={{
          backgroundImage: `url(${bgRight20})`,
          backgroundPosition: 'right',
          backgroundSize: 'auto'
        }}
      />
      <div
        className="bg-holder"
        style={{
          backgroundImage: `url(${bgLeft20})`,
          backgroundPosition: 'left',
          backgroundSize: 'auto'
        }}
      />

      <div className="position-relative">
        <section className="footer-default">
          <div className="container-small px-lg-7 px-xxl-3">
            <Row className="position-relative">
              <Col xs={12} lg={5} className="mb-4 order-0 order-sm-0">
                <Link to="#!">
                  <img className="mb-3" src={logoWhite} height="48" alt="" />
                </Link>
                <h3 className="text-white">GIGA PROS</h3>
                <p className="text-white opacity-50">
                  Todos Direitos Reservados.
                </p>
                <p className="text-white opacity-50">
                  CNPJ: 51.387.812/0001-72
                </p>
                <p className="text-white opacity-50">
                  Endereço: Av. dos Holandeses/Cons. Hilton Rodrigues,
                  Parq/Atlantico, Número 15, Olho D'Agua, 65.065-180, São Luís -
                  MA
                </p>
                <p className="text-white opacity-50">
                  Contatos:
                  <br /> +55 (11) 97484-9856 (Atendimento)
                  <br /> +55 (11) 94279-1230 (Atendimento)
                  <br /> +55 (98) 3011-6152 (Recepção)
                </p>
                <p className="text-white opacity-50">
                  Email: contatodagiga@gmail.com
                </p>
              </Col>
              <Col lg={7}>
                <Row className="justify-content-between">
                  <Col xs={6} sm={4} lg={3} className="mb-3 order-2 order-sm-1">
                    <FooterList
                      label="Ajuda"
                      className="mb-4"
                      items={['Sobre', 'Contato']}
                    />
                  </Col>
                  <Col xs={6} sm={4} lg={3} className="mb-3 order-3 order-sm-2">
                    <FooterList
                      label="Suporte"
                      items={['Privacidade', 'Comunidade', 'Contato', 'FAQ']}
                    />
                  </Col>
                  <Col xs={6} md={4} lg={3} className="mb-3 order-3 order-sm-2">
                    <FooterList
                      label="Redes Sociais"
                      items={['Instagram', 'Linkedin']}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CurriculoFooter;
