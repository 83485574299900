import { Col, Row } from 'react-bootstrap';
import { defaultFeatures } from 'data/landing/default-landing-data';
import FeatureSection from './FeatureSection';

const Features = () => {
  return (
    <section className="bg-body pt-15 pb-18" id="features">
      <div className="container-small px-lg-7 px-xxl-3">
        <div className="position-relative z-2">
          <Row className="mb-12">
            <Col lg={6} className="text-center text-lg-start pe-xxl-3">
              <h3 className="text-primary fw-bolder mb-4">
                Você tem esses problemas?
              </h3>
              <h4 className="mb-3 text-body-emphasis lh-base">
                Empresas internacionais te enviam propostas, mas você não está
                confiante com o nível atual do seu inglês para a entrevista?
              </h4>
            </Col>
            <Col sm={6} lg={3} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed border-translucent ps-4">
                  <div>
                    <h4 className="mb-2">
                      Você ganha bem no Brasil, mas está chegando ao limite do
                      seu teto salarial?
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column">
                <div className="border-start-lg border-dashed border-translucent ps-4">
                  <div>
                    <h4 className="mb-2">
                      Você já sabe inglês, mas não se sente confiante em falar?
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {defaultFeatures.map((feature, index) => (
            <FeatureSection
              key={feature.label}
              feature={feature}
              orderReverse={index === 1 || index === 3}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
