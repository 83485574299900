/* eslint-disable */
// @ts-nocheck
import { useEffect } from 'react';

interface FacebookPixelProps {
  event: 'Purchase' | 'AddToCart' | 'InitiateCheckout' | 'AddPaymentInfo' | 'PageView';
  purchaseValue?: number;
  currency?: string;
}

const FacebookPixel: React.FC<FacebookPixelProps> = ({ event, purchaseValue, currency = 'BRL' }) => {
  useEffect(() => {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    window.fbq('init', '681379467191446'); // Insert your Pixel ID here
    window.fbq('init', '1631689247617950'); // Insert your Pixel ID here

    // Track events based on the 'event' prop
    switch (event) {
      case 'Purchase':
        if (purchaseValue > 0) {
          window.fbq('track', 'Purchase', {
            value: purchaseValue,
            currency: currency
          });
        }
        break;
      case 'AddToCart':
        window.fbq('track', 'AddToCart');
        break;
      case 'InitiateCheckout':
        window.fbq('track', 'InitiateCheckout');
        break;
      case 'AddPaymentInfo':
        window.fbq('track', 'AddPaymentInfo');
        break;
      default:
        window.fbq('track', 'PageView');
    }
  }, [event, purchaseValue, currency]);

  return null;
};

export default FacebookPixel;
